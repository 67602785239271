

interface IProps {
  className?: string
}
export default function FileWithStroke({ className = '' }:IProps) {
  return <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.6667 3.02612V8.53351C18.6667 9.28025 18.6667 9.65362 18.812 9.93883C18.9398 10.1897 19.1438 10.3937 19.3947 10.5215C19.6799 10.6668 20.0533 10.6668 20.8 10.6668H26.3074M18.6667 22.6668H10.6667M21.3333 17.3334H10.6667M26.6667 13.3177V22.9334C26.6667 25.1736 26.6667 26.2937 26.2307 27.1494C25.8472 27.902 25.2353 28.514 24.4826 28.8974C23.627 29.3334 22.5069 29.3334 20.2667 29.3334H11.7333C9.49312 29.3334 8.37301 29.3334 7.51737 28.8974C6.76472 28.514 6.1528 27.902 5.7693 27.1494C5.33333 26.2937 5.33333 25.1736 5.33333 22.9334V9.06675C5.33333 6.82654 5.33333 5.70643 5.7693 4.85079C6.1528 4.09814 6.76472 3.48622 7.51737 3.10272C8.37301 2.66675 9.49312 2.66675 11.7333 2.66675H16.0157C16.9941 2.66675 17.4832 2.66675 17.9436 2.77727C18.3517 2.87526 18.7419 3.03687 19.0998 3.25619C19.5035 3.50355 19.8494 3.84946 20.5412 4.54126L24.7921 8.79223C25.484 9.48404 25.8299 9.82994 26.0772 10.2336C26.2965 10.5915 26.4582 10.9817 26.5561 11.3898C26.6667 11.8502 26.6667 12.3394 26.6667 13.3177Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
}

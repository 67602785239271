import ReactPaginate from 'react-paginate'
import { useSearchParams } from 'react-router-dom'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { cn } from '../../lib/utils'

interface IProps {
  totalPageSize: number
  currentPage: number
  className?: string
}

export default function Pagination ({ className = '', totalPageSize, currentPage }: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const onPageChange = (selectedItem: { selected: number }) => {
    searchParams.set('page', String(selectedItem.selected))
    setSearchParams(searchParams)
  }

  return (
    <ReactPaginate
      pageCount={totalPageSize}
      initialPage={currentPage}
      onPageChange={onPageChange}
      nextLabel={<ChevronRight className="text-text-gray" />}
      previousLabel={<ChevronLeft className="text-text-gray" />}
      nextClassName={`w-[32px] h-[32px] flex justify-center items-center rounded-lg`}
      previousClassName={`w-[32px] h-[32px] flex justify-center items-center rounded-lg`}
      className={cn("flex justify-center items-center gap-2", className)}
      activeClassName="bg-primary text-white"
      pageClassName="w-[32px] h-[32px] flex justify-center items-center rounded-lg"
      pageRangeDisplayed={5}
    />
  )
}

import { cn } from '../../lib/utils'
import { ChevronLeft } from 'lucide-react'
import { Link } from 'react-router-dom'


interface IProps {
  className?: string
  to: string
}

export default function LinkBack({ className = '', to }: IProps) {
  return (
    <Link className={cn('flex items-center text-[#0193EB] text-sm gap-0.5 font-medium', className)} to={to}>
      <ChevronLeft className=" w-[24px] h-[24px]" />
      Артқа
    </Link>
  )
}

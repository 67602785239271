import { Input } from '../ui/input'
import Search from '../../assets/icons/Search'
import { cn } from '../../lib/utils'
import { KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { useDebounce } from '@uidotdev/usehooks'
import { SearchDocumentData, useSearchDocumentMutation } from '../../queries/home/mutations'
import { ChevronRight } from 'lucide-react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import Cross from '../../assets/icons/Cross'

interface IProps {
  className?: string
}
export default function SearchField({ className = '' }: IProps) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(true)

  const { mutate, data } = useSearchDocumentMutation()

  const inputRef = useRef<HTMLInputElement>(null)

  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 500)

  useEffect(() => {
    if (debouncedSearchValue) {
      mutate({ nameSearchPattern: searchValue })
    }
  }, [debouncedSearchValue])

  const [dimensions, setDimensions] = useState<{ width?: number | null; height?: number | null }>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const getDimensions = () => ({
      width: inputRef?.current?.offsetWidth,
      height: inputRef?.current?.offsetHeight,
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (inputRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [inputRef])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      navigate(`/search?nameSearchPattern=${searchValue}&page=0`)
    }
  }

  return (
    <div className={cn('relative max-w-[900px] tablet:mx-auto tablet:px-8 desktop:px-0', className)}>
      <Input
        ref={inputRef}
        onChange={(event) => setSearchValue(event.target.value)}
        value={searchValue}
        onKeyDown={handleKeyDown}
        onFocus={() => setMenuOpen(true)}
        onBlur={() => setTimeout(() => setMenuOpen(false), 1000)}
        className={cn(
          'shadow border-none h-[56px] pl-12 placeholder:text-gray peer desktop:h-[64px] desktop:text-lg desktop:pl-16 tablet:pl-13',
          {
            'rounded-b-none': searchValue && menuOpen,
          },
        )}
        placeholder="Құжаттың атын теріңіз"
        icon={
          <Search className="peer-focus:text-secondary transition text-text-gray absolute ml-4 w-[24px] h-[24px] desktop:ml-6" />
        }
        endIcon={searchValue ? <Cross onClick={() => setSearchValue('')} className="peer-focus:text-secondary cursor-pointer transition text-text-gray absolute right-0 mr-4 w-[26px] h-[26px] desktop:mr-6 hover:text-secondary" /> : <></>}
      />
      {searchValue && menuOpen && (data?.totalSize ?? 0) > 0 && (
        <div
          className="shadow bg-white absolute top-[56px] rounded-b-lg desktop:top-[64px] z-30"
          style={{ width: inputRef?.current?.offsetWidth }}>
          {data?.responseData?.map((item, index) => (
            <SearchItem
              key={index}
              {...item}
              width={dimensions?.width ?? 0}
              searchValue={searchValue}
            />
          ))}
        </div>
      )}
    </div>
  )
}

interface ISearchItemProps extends SearchDocumentData {
  searchValue: string
  width: number
}

const SearchItem = ({ nameKz, parentSubcategory, width, searchValue, id }: ISearchItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const words = nameKz.split(' ')
  const firstWord = words[0]
  const otherWords = words.slice(1).join(' ')

  const handleClick = () => {
    navigate({
      pathname: `/category/${parentSubcategory.parentCategory.id}/${parentSubcategory.id}/${id}`,
      search: createSearchParams({
        backUrl: `${location.pathname}${location.search}`
      }).toString()
    })
  }

  return (
    <div onClick={handleClick} className="px-3 py-3 tablet:pl-5 tablet:pr-4 desktop:pl-7 tablet:py-4 flex gap-3 desktop:gap-5 items-center cursor-pointer text-sm text-text-gray hover:bg-[#ECF8FF] active:bg-[#ECF8FF] focus-visible:bg-[#ECF8FF] transition">
      <Search className="hidden tablet:block max-w-[20px] max-h-[20px] flex-shrink-0 flex-grow" />
      <div className="text-left flex flex-col gap-0.5 tablet:gap-1">
        <div
          className="group tablet:text-base w-fit">
          {highlightMatch(firstWord, searchValue)} {otherWords}
        </div>
        <div
          className="overflow-hidden truncate flex items-center text-xs tablet:text-sm flex-wrap">
          {parentSubcategory.parentCategory.nameKz}{' '}
          <ChevronRight className="w-[16px] h-[16px] mx-1 mt-0.5" />
          {parentSubcategory.nameKz}
        </div>
      </div>
    </div>
  )
}

const highlightMatch = (text: string, query: string): ReactNode => {
  const parts = text.split(new RegExp(`(${query})`, 'gi'))
  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <span key={index} className="text-secondary font-semibold">
        {part}
      </span>
    ) : (
      <span key={index}>{part}</span>
    ),
  )
}

import axios, { AxiosError } from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL

interface CustomErrorData {
  messageKz?: string
}

export interface CustomError extends AxiosError<CustomErrorData> {}
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  },
)

export { axios as Api }

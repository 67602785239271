import { Outlet, useLocation } from 'react-router-dom'
import Header from '../components/shared/Header'
import React, { useMemo } from 'react'
import { cn } from '../lib/utils'
import Footer from '../components/shared/Footer'
import { Toaster } from '../components/ui/toaster'
import { useSaveHistory } from '../hooks/useSaveHistory'

const FULL_WIDTH_PAGES = ['/']

export default function MainLayout() {
  useSaveHistory()
  const location = useLocation()
  const isFullWidthPage = useMemo(() => {
    return FULL_WIDTH_PAGES.includes(location.pathname)
  }, [location.pathname])

  return (
    <section className={cn({
      'background': !isFullWidthPage,
    })}>
      <Header />

      <main
        className={cn('w-full mx-auto mt-[56px] tablet:mt-[72px] desktop:mt-[80px]', {
          "max-w-[1120px]": !isFullWidthPage,
        })}>
        <Outlet />
      </main>
      <Toaster />
      <Footer />
    </section>
  )
}

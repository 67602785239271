import { SearchDocumentData } from '../home/mutations'
import { PaginationResponse } from '../types'
import { Api } from '../../lib/axios'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

type SearchDocumentState = {
  nameSearchPattern: string
  pageIndex: number
  pageSize: number
}

type SearchDocumentResponse = PaginationResponse & {
  responseData: SearchDocumentData[]
  totalSize: number
}

const searchDocument = async ({ nameSearchPattern, pageIndex, pageSize }: SearchDocumentState): Promise<SearchDocumentResponse> => {
  const response = await Api.get('/main-page/documents/search', {
    params: {
      pageSize,
      pageIndex,
      nameSearchPattern,
    },
  })

  return { ...response.data, totalPageSize: Math.ceil(response.data.totalSize / pageSize) }
}

const SEARCH_DOCUMENT_QUERY_KEY = 'searchDocument'

export const useSearchDocumentQuery = ({ nameSearchPattern, pageIndex, pageSize }:SearchDocumentState) => {
  return useQuery({
    queryKey: [SEARCH_DOCUMENT_QUERY_KEY, nameSearchPattern, pageIndex, pageSize],
    queryFn: () => searchDocument({ nameSearchPattern, pageIndex, pageSize }),
    placeholderData: keepPreviousData
  })
}

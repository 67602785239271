import { IDocument } from '../subCategoryDocument/mutations'
import { Api } from '../../lib/axios'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

type DocumentByIdState = {
  categoryId: string;
  subCategoryId: string;
  documentId: string;
}

type DocumentById = {
  responseData: IDocument;
}

const fetchDocumentById = async ({ categoryId, subCategoryId, documentId }: DocumentByIdState): Promise<DocumentById> => {
  const response = await Api(`/single-document-page/category/${categoryId}/subcategories/${subCategoryId}/document/${documentId}`)
  return response.data
}

const DOCUMENT_BY_ID_QUERY_KEY = 'documentById'

export const useDocumentByIdQuery = ({ categoryId, subCategoryId, documentId }: DocumentByIdState) => {
  return useQuery({
    queryKey: [DOCUMENT_BY_ID_QUERY_KEY, { categoryId, subCategoryId, documentId }],
    queryFn: () => fetchDocumentById({ categoryId, subCategoryId, documentId }),
    placeholderData: keepPreviousData,
    retry: false
  })
}

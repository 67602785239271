import LinkBack from '../components/shared/LinkBack'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDocumentByIdQuery } from '../queries/document/queries'
import { Button } from '../components/ui/button'
import Share from '../assets/icons/Share'
import Download from '../assets/icons/Download'
import Print from '../assets/icons/Print'
import { Input } from '../components/ui/input'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { KeyboardEvent, useEffect, useMemo } from 'react'
import { Loader2 } from 'lucide-react'
import * as React from 'react'
import {
  useDocumentSubscribeEmailMutation,
  useDocumentViewedMutation,
} from '../queries/document/mutations'
import { useDocumentStore } from '../store/documentStore'
import { shallow } from 'zustand/shallow'
import { useToast } from '../components/ui/use-toast'
import useDocumentActions from '../hooks/useDocumentActions'


export default function Document() {
  const params = useParams();
  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();

  const categoryId = params.categoryId;
  const subCategoryId = params.subCategoryId;
  const documentId = params.documentId;

  const backUrl = searchParams.get('backUrl') ?? '/'

  const { handlePrint, handleDownload } = useDocumentActions()

  const viewedDocumentList = useDocumentStore(state => state.viewedDocumentList, shallow)
  const setViewedDocument = useDocumentStore(state => state.setViewedDocument)
  const modifiedDocumentId = `${categoryId}-${subCategoryId}-${documentId}`

  const { data, isError, isSuccess, error } = useDocumentByIdQuery({ categoryId: categoryId ?? '', subCategoryId: subCategoryId ?? '', documentId: documentId ?? '' })
  const { toast } = useToast()

  useEffect(() => {
    if (error) {
      toast({
        // @ts-ignore
        description: error?.response?.data?.responseClientMessage ?? 'Қате кетті',
        variant: 'destructive',
      })
      navigate('/')
    }
  }, [error])

  useEffect(() => {
    if (isSuccess && !viewedDocumentList.includes(modifiedDocumentId)) {
      mutateDocumentViewed({ categoryId: categoryId ?? '', subCategoryId: subCategoryId ?? '', documentId: documentId ?? '' })
      setViewedDocument(modifiedDocumentId);
    }
  }, [isSuccess])

  const { mutate: mutateDocumentViewed } = useDocumentViewedMutation()

  const docsList = useMemo(() => {
    return [{
      uri: data?.responseData?.sourceContentBlob?.uri ?? ''
    }]
  }, [data?.responseData?.sourceContentBlob])

  return (
    <section className="pt-4 pb-[64px] px-4 min-h-[850px] tablet:px-6 desktop:px-0 desktop:pt-[40px]">
      <LinkBack className="mb-4 tablet:mb-8 desktop:mb-[32px]" to={backUrl ?? `/category/${categoryId}/${subCategoryId}`} />

      <h2 className="text-left text-2xl font-semibold pb-[24px] tablet:pb-6 desktop:pb-8 desktop:text-[28px]">{data?.responseData.nameKz}</h2>

      <div className="flex flex-col desktop:flex-row-reverse desktop:gap-8">
        <div className="flex flex-col gap-4 tablet:max-w-[660px] desktop:max-w-[350px] w-full mb-4 tablet:mb-8 desktop:gap-8">
          <div className="p-2 flex flex-col gap-2 bg-white rounded-lg tablet:p-4 tablet:gap-4 tablet:flex-row desktop:flex-col desktop:p-6">
            {data?.responseData?.filledSampleContentBlob?.uri && (
              <Button onClick={() => window.open(data?.responseData?.filledSampleContentBlob?.uri, '_blank')} className="bg-[#E7F6FF] text-primary h-[48px] w-full rounded-lg">
                <Share className="w-[24px] h-[24px] mr-2" />
                <span className="text-base font-medium">Толтырылған үлгіні көру</span>
              </Button>
            )}

            <Button onClick={() => handleDownload(data?.responseData?.sourceContentBlob?.uri, categoryId ?? '', subCategoryId ?? '', documentId ?? '')} className="bg-[#E7F6FF] text-primary h-[48px] w-full rounded-lg">
              <Download className="w-[24px] h-[24px] mr-2" />
              <span className="text-base font-medium">Жүктеу</span>
            </Button>

            <Button onClick={() => handlePrint(data?.responseData?.sourceContentBlob?.uri, data?.responseData?.sourceContentBlob?.extension, categoryId ?? '', subCategoryId ?? '', documentId ?? '')} className="bg-[#E7F6FF] text-primary h-[48px] w-full rounded-lg">
              <Print className="w-[24px] h-[24px] mr-2" />
              <span className="text-base font-medium">Басып шығару</span>
            </Button>
          </div>

          <EmailSection />

        </div>

        <DocViewer
          className="w-full bg-white rounded-lg overflow-hidden min-h-[640px] tablet:min-h-[1000px] [&_#header-bar]:!hidden"
          documents={docsList ?? []}
          initialActiveDocument={docsList?.[1]}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableFileName: true
            },
            loadingRenderer: {
              overrideComponent: () => <div className="flex justify-center items-center h-[600px] tablet:h-[800px] w-full">
                <Loader2 className="animate-spin w-[40px] h-[40px] mx-auto" />
              </div>,
              showLoadingTimeout: false
            },
          }}
        />
      </div>
    </section>
  )
}

const EmailSection = () => {
  const params = useParams();
  const { toast } = useToast()

  const categoryId = params.categoryId;
  const subCategoryId = params.subCategoryId;
  const documentId = params.documentId;

  const { mutate, isPending, isSuccess } = useDocumentSubscribeEmailMutation()

  useEffect(() => {
    if (isSuccess) {
      toast({
        description: 'Сіздің поштаңызға сәтті жіберілді!',
        variant: 'success',
      })
    }
  }, [isSuccess])

  const [email, setEmail] = React.useState<string>('')

  const isValid = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const handleSubmit = () => {
    if (!isValid) {
      mutate({ categoryId: categoryId ?? '', subCategoryId: subCategoryId ?? '', documentId: documentId ?? '', email })
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <div className="py-3 px-2 bg-white rounded-lg tablet:p-4 tablet:flex tablet:flex-row tablet:gap-4 desktop:p-6 desktop:flex-col">
      <div className="w-full">
        <Input onKeyDown={handleKeyDown} value={email} disabled={isPending} onChange={(event) => setEmail(event.target.value)} className="border !border-[#D6D9E0] w-full rounded-lg mb-4 h-[48px] text-base px-4 tablet:m-0 placeholder:text-text-gray" placeholder="Электрондық поштаны еңгізіңіз" />
      </div>
      <Button onClick={handleSubmit} isLoading={isPending} disabled={isValid || isPending} className="w-full h-[48px] tablet:max-w-[140px] text-base font-normal desktop:max-w-full">
        Жіберу
      </Button>
    </div>
  )
}



interface IProps {
  className?: string
}
export default function File({ className = '' }:IProps) {
  return <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.33332 1.51314V4.26683C9.33332 4.6402 9.33332 4.82688 9.40599 4.96949C9.4699 5.09493 9.57189 5.19692 9.69733 5.26083C9.83994 5.3335 10.0266 5.3335 10.4 5.3335H13.1537M13.3333 6.65898V11.4668C13.3333 12.5869 13.3333 13.147 13.1153 13.5748C12.9236 13.9511 12.6176 14.2571 12.2413 14.4488C11.8135 14.6668 11.2534 14.6668 10.1333 14.6668H5.86666C4.74655 14.6668 4.1865 14.6668 3.75868 14.4488C3.38235 14.2571 3.07639 13.9511 2.88464 13.5748C2.66666 13.147 2.66666 12.5869 2.66666 11.4668V4.5335C2.66666 3.41339 2.66666 2.85334 2.88464 2.42552C3.07639 2.04919 3.38235 1.74323 3.75868 1.55148C4.1865 1.3335 4.74655 1.3335 5.86666 1.3335H8.00784C8.49702 1.3335 8.74161 1.3335 8.97179 1.38876C9.17586 1.43775 9.37095 1.51856 9.54989 1.62822C9.75173 1.7519 9.92468 1.92485 10.2706 2.27075L12.3961 4.39624C12.742 4.74214 12.9149 4.91509 13.0386 5.11693C13.1483 5.29587 13.2291 5.49096 13.2781 5.69503C13.3333 5.92521 13.3333 6.1698 13.3333 6.65898Z" stroke="#667A8B" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

}

import { ICategory } from '../../queries/home/queries'
import File from '../../assets/icons/File'
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'


interface IProps extends ICategory {
}

export default function CategoryItem({ id, nameKz, iconBlob, documentsCount}: IProps) {
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();

  const truncatedText: string = useMemo(() => {
    if (breakpoint === Breakpoint.mobile) {
      return truncateText(nameKz, 75)
    }

    if (breakpoint === Breakpoint.tablet) {
      return truncateText(nameKz, 60)
    }

    return truncateText(nameKz, 45)
  }, [breakpoint, nameKz])

  const handleClick = (id: number) => {
    navigate(`/category/${id}`)
  }

  return (
    <div onClick={() => handleClick(id)} className="relative h-[170px] w-full flex flex-col rounded-xl bg-[#F9F9F9] rounded-tl-none text-left shadow transition-all hover:shadow-none duration-300 cursor-pointer tablet:h-[180px]">
      <div className="absolute rounded-tl-[1300px] rounded-tr-full rounded-br-full h-[100px] top-[-20px] w-6/12 left-0 bg-[#F9F9F9] tablet:top-[-25px] tablet:h-[100px] tablet:rounded-tl-[1600px] left-[-1px]"></div>

      <img className="w-[32px] h-[32px] desktop:w-[40px] desktop:h-[40px] desktop:ml-6 relative z-10 ml-4 mb-8 desktop:mb-6" src={iconBlob.uri} />

      <div className="ml-4 desktop:ml-6 mr-2 desktop:mr-3 font-medium relative z-10">{truncatedText}</div>
      <div className="ml-4 desktop:ml-6 desktop:mb-6 mt-auto mb-4 text-sm text-gray flex items-center gap-2 tablet:mb-[18px]"><File className="w-[16px] h-[16px]" /> {documentsCount?.documentCount ?? 1} құжат</div>
    </div>
  )
}

function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text;
  }

  const truncatedText = text.substring(0, maxLength - 3);
  return truncatedText + '...';
}

import MobileBg1x from '../assets/images/home/mobile-bg-1x.png'
import MobileBg2x from '../assets/images/home/mobile-bg-2x.png'
import TabletBg1x from '../assets/images/home/tablet-bg-2x.png'
import TabletBg2x from '../assets/images/home/tablet-bg-2x.png'
import DesktopBg1x from '../assets/images/home/desktop-bg-2x.png'
import DesktopBg2x from '../assets/images/home/desktop-bg-2x.png'
import { useDocumentCountQuery } from '../queries/home/queries'
import SearchField from '../components/home/Search'
import CategorySection from '../components/home/CategorySection'

export default function Home() {
  const { data } = useDocumentCountQuery()
  return (
    <section>
      <div className="w-full max-h-[560px] tablet:max-h-[480px] h-screen relative desktop:max-h-[640px] desktop:bg-[#F9F9F9]">
        <div className="pt-[64px] desktop:pt-[100px] relative z-[2]">
          <div className="text-xs text-white bg-primary px-2 w-fit rounded-lg mx-auto h-[28px] tablet:h-[36px] flex items-center mb-4 tablet:text-base tablet:px-5 desktop:h-[40px]">
            Барлығы {data?.responseData?.documentCount ?? 0} құжат бар{' '}
          </div>

          <div className="text-2xl font-semibold mx-auto w-[320px] text-center mb-[30px] tablet:text-[28px] tablet:leading-snug tablet:w-[600px] desktop:text-[40px] desktop:w-[900px] desktop:mb-[32px]">
            Қазақ тіліндегі құжаттардың <br /> электронды қоры
          </div>

          <SearchField className="mx-4" />
        </div>
        <img
          className="tablet:hidden max-w-none w-full h-full object-cover absolute top-0 left-0 z-[-1]"
          srcSet={`${MobileBg1x} 1x, ${MobileBg2x} 2x`}
        />

        <img
          className="hidden tablet:block max-w-none w-full h-full object-cover absolute top-0 left-0 z-[-1]"
          srcSet={`${TabletBg1x} 1x, ${TabletBg2x} 2x`}
        />

        <img
          className="hidden desktop:block w-full h-full object-cover absolute top-0 z-[1] max-w-[1440px] left-1/2 -translate-x-1/2"
          srcSet={`${DesktopBg1x} 1x, ${DesktopBg2x} 2x`}
        />
      </div>

      <CategorySection />
    </section>
  )
}

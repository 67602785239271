interface IProps extends React.SVGProps<SVGSVGElement> {
  className?: string
}

export default function MoveRight({ className = '', ...rest }: IProps) {
  return (
    <svg className={className} {...rest} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 12L20 12M20 12L14 6M20 12L14 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

export default function OpenApi() {
  return (
    <section className="pt-[40px] pb-[80px] px-4 min-h-[500px] tablet:px-6 desktop:px-0 tablet:pt-[56px] tablet:pb-[80px] desktop:pt-[64px] desktop:pb-[80px] text-left">
      <h2 className="text-left text-2xl font-semibold pb-[40px] tablet:pb-[40px] tablet:text-[28px] desktop:pb-[40px] desktop:text-[36px]">Ашық API </h2>

      <div className="bg-white py-4 pt-0 px-0 rounded-lg shadow [&_.information-container]:!pt-0.5">
        <SwaggerUI url="https://front-api.qujat.kz/swagger/openapi/swagger.json" />
      </div>
    </section>
  )
}

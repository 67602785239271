import { Api } from '../../lib/axios'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { ISubCategory } from '../subCategory/queries'
import { IDocument } from './mutations'

type SubCategoryByIdState = {
  parentCategoryId: string;
  subCategoryId: string;
}

type SubCategoryById = {
  responseData: ISubCategory
}

const fetchSubCategoryById = async ({ parentCategoryId, subCategoryId }: SubCategoryByIdState): Promise<SubCategoryById> => {
  const response = await Api.get(`/subcategories-page/${parentCategoryId}/subcategories/${subCategoryId}`)
  return response.data
}

const SUB_CATEGORY_BY_ID_QUERY_KEY = 'subCategoryById'

export const useSubCategoryByIdQuery = ({ parentCategoryId, subCategoryId }: SubCategoryByIdState) => {
  return useQuery({
    queryKey: [SUB_CATEGORY_BY_ID_QUERY_KEY, parentCategoryId, subCategoryId],
    queryFn: () => fetchSubCategoryById({ parentCategoryId, subCategoryId }),
    retry: false
  })
}

type PopularDocuments = {
  responseData: IDocument[]
}

const fetchPopularDocuments = async (): Promise<PopularDocuments> => {
  const response = await Api.get(`/main-page/documents/top`)
  return response.data
}

const POPULAR_DOCUMENTS_QUERY_KEY = 'popularDocuments'

export const usePopularDocumentsQuery = () => {
  return useQuery({
    queryKey: [POPULAR_DOCUMENTS_QUERY_KEY],
    queryFn: () => fetchPopularDocuments(),
    placeholderData: keepPreviousData,
  })
}

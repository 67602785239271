import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface BearState {
  printedDocumentList: string[]
  setPrintedDocument: (documentId: string) => void
  viewedDocumentList: string[]
  setViewedDocument: (documentId: string) => void
  downloadedDocumentList: string[]
  setDownloadedDocument: (documentId: string) => void
}

export const useDocumentStore = create(
  persist<BearState>(
    (set) => ({
      printedDocumentList: [],
      setPrintedDocument: (documentId: string) => set((state) => ({ printedDocumentList: [...state.printedDocumentList, documentId] })),
      viewedDocumentList: [],
      setViewedDocument: (documentId: string) => set((state) => ({ viewedDocumentList: [...state.viewedDocumentList, documentId] })),
      downloadedDocumentList: [],
      setDownloadedDocument: (documentId: string) => set((state) => ({ downloadedDocumentList: [...state.downloadedDocumentList, documentId] })),
    }),
    {
      name: 'document-storage',
    },
  ),
)

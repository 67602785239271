
interface IProps {
  className?: string
}

export default function Print({ className = '' }: IProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path d="M15.6 15.6498H20.1C20.5971 15.6498 21 15.2469 21 14.7498V7.54984C21 7.05277 20.5971 6.64984 20.1 6.64984H15.6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.40002 15.6498H3.9C3.40294 15.6498 3 15.2469 3 14.7498V7.54984C3 7.05277 3.40294 6.64984 3.9 6.64984H8.40002" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.59998 6.64984V9.34984H17.4V6.64984" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.40002 6.64998V3.04999H15.6V6.64998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.40002 13.8499V20.1499H15.6V13.8499" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.1 16.55H12.9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.04993 12.95H16.95" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

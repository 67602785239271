import { useSearchParams } from 'react-router-dom'
import { useCategoriesQuery } from '../../queries/home/queries'
import Pagination from '../ui/Pagination'
import { useMemo } from 'react'
import CategoryItem from '../shared/CategoryItem'


const DEFAULT_PAGE_SIZE = 12


export default function CategorySection() {
  const [searchParams] = useSearchParams();

  const page = useMemo<number>(() => {
    return Number(searchParams.get('page') ?? 0)
  }, [searchParams.get('page')]);

  const { data } = useCategoriesQuery({ pageIndex: Number(page ?? 0), pageSize: DEFAULT_PAGE_SIZE })

  return (
    <section className="bg-white px-4 mt-10 relative pb-[80px] tablet:px-6 desktop:px-0">
      <div className="absolute w-3/6 h-[100px] top-[-60px] left-0 rounded-r-full bg-white desktop:w-6/12 desktop:top-[-80px] desktop:h-[500px]"></div>

      <div className="w-full h-[70px] tablet:h-[80px] text-left flex flex-col gap-2 relative z-[1] max-w-[1120px] desktop:mx-auto desktop:gap-3 desktop:h-[145px] desktop:pt-10">
        <h2 className="text-xl font-semibold desktop:text-[28px]">Барлық категориялар</h2>
      </div>
      <div className="grid grid-cols-1 min-[540px]:grid-cols-2 min-[767px]:grid-cols-3 min-[767px]:gap-x-6 min-[540px]:gap-x-6 gap-12 mb-8 tablet:mb-[48px] max-w-[1120px] desktop:mx-auto desktop:grid-cols-4 desktop:gap-x-8 desktop:gap-y-16">
        {data?.responseData?.map((category) => (
          <CategoryItem key={category.id} {...category} />
        ))}
      </div>


      {(data?.totalPageSize ?? 1) > 1 && <Pagination currentPage={page} totalPageSize={data?.totalPageSize ?? 1} />}

    </section>
  )
}

import { Api } from '../../lib/axios'
import { useMutation } from '@tanstack/react-query'
import { PaginationResponse } from '../types'

type SearchDocumentState = {
  nameSearchPattern: string
}

type SearchDocumentResponse = PaginationResponse & {
  responseData: SearchDocumentData[]
  totalSize: number
}

export interface SearchDocumentData {
  id: string;
  nameKz: string
  parentSubcategory: {
    id: string;
    nameKz: string
    parentCategory: {
      id: string;
      nameKz: string
    }
  }
}

const searchDocument = async (nameSearchPattern: string): Promise<SearchDocumentResponse> => {
  const response = await Api.get('/main-page/documents/search', {
    params: {
      pageSize: 3,
      nameSearchPattern,
    },
  })

  return { ...response.data, totalPageSize: Math.ceil(response.data.totalSize / 12) }
}

export const useSearchDocumentMutation = () => {
  return useMutation<SearchDocumentResponse, unknown, SearchDocumentState>({
    mutationFn: async (state) => searchDocument(state.nameSearchPattern),
  })
}

import { create } from 'zustand';
import { persist } from 'zustand/middleware'

interface Store {
  history: string[];
  setHistory: (history: string) => void;
  pop: () => void;
  getPrevious: () => string;
}

const sliceUntil = (history: string[], url: string): string[] => {
  const newHistory = [...history];

  for (let i = newHistory.length - 1; i >= 0; i--) {
    if (
      newHistory[i].includes('page') &&
      newHistory[i].split('?')[0] === url.split('?')[0]
    ) {
      newHistory.pop();
    } else {
      break;
    }
  }

  return newHistory;
};

export const useHistoryStore = create(
  persist<Store>((set, getState) => ({
    history: ['/'],
    setHistory: (history) => {
      const { history: currentHistory } = getState();

      if (currentHistory?.[currentHistory.length - 1] === history) return;

      set({ history: [...currentHistory, history] });
    },
    pop: () => {
      const { history } = getState();
      const curr = history?.[history.length - 1] ?? '/';

      if ((history?.[history.length - 1] ?? '/').includes('page')) {
        set({ history: sliceUntil(history, curr) });
      } else {
        set({ history: history.slice(0, history.length - 1) });
      }
    },
    getPrevious: () => {
      const { history } = getState();

      return history?.[history.length - 2] ?? '/';
    },
  }), {
    name: 'history-storage',
  })
);

import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainLayout from './layouts/MainLayout'
import Home from './pages/Home'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import SubCategory from './pages/SubCategory'
import SubCategoryDocuments from './pages/SubCategoryDocuments'
import Document from './pages/Document'
import LegalActList from './pages/LegalActList'
import Search from './pages/Search'
import OpenApi from './pages/OpenApi'

const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route element={<MainLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/category/:categoryId/" element={<SubCategory />} />
              <Route path="/category/:categoryId/:subCategoryId" element={<SubCategoryDocuments />} />
              <Route path="/category/:categoryId/:subCategoryId/:documentId" element={<Document />} />
              <Route path="/legal" element={<LegalActList />} />
              <Route path="/search" element={<Search />} />
              <Route path="/open-api" element={<OpenApi />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  )
}

export default App

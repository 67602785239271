import { Api } from '../../lib/axios'
import { ICategory } from '../home/queries'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { PaginationResponse } from '../types'

type CategoryIdState = {
  id: string;
}

type CategoryById = PaginationResponse & {
  responseData: ICategory
}

const fetchCategoryById = async (id: string): Promise<CategoryById> => {
  const response = await Api.get(`/main-page/categories/${id}`)
  return response.data
}

const CATEGORY_BY_ID_QUERY_KEY = 'categoryById'

export const useCategoryByIdQuery = ({ id }: CategoryIdState) => {
  return useQuery({
    queryKey: [CATEGORY_BY_ID_QUERY_KEY, id],
    queryFn: () => fetchCategoryById(id),
    retry: false
  })
}


type SubCategoriesState = {
  id: string;
  pageIndex: number;
  pageSize: number;
}

export interface ISubCategory {
  id: number;
  nameKz: string;
  parentCategoryId: number;
  documentsCount: {
    documentCount: number
  }
}

type SubCategories = PaginationResponse & {
  responseData: ISubCategory[]
}

const fetchSubCategories = async ({ id, pageIndex, pageSize }: SubCategoriesState): Promise<SubCategories> => {
  const response = await Api.get(`/subcategories-page/${id}/subcategories`, {
    params: {
      pageSize,
      pageIndex,
    }
  })
  return { ...response.data, totalPageSize: Math.ceil(response.data.totalSize / pageSize) }
}

const SUB_CATEGORIES_QUERY_KEY = 'subCategories'

export const useSubCategoriesQuery = ({ id, pageIndex, pageSize }: SubCategoriesState) => {
  return useQuery({
    queryKey: [SUB_CATEGORIES_QUERY_KEY, id, pageSize, pageIndex],
    queryFn: () => fetchSubCategories({ id, pageIndex, pageSize }),
    placeholderData: keepPreviousData
  })
}

import { PaginationResponse } from '../types'
import { Api } from '../../lib/axios'
import { keepPreviousData, useQuery } from '@tanstack/react-query'


type LegalActQueryState = {
  pageIndex: number;
  pageSize: number;
}

type LegalAct = PaginationResponse & {
  responseData: ILegalAct[]
}

export interface ILegalAct {
  id: string;
  nameKz: string;
  uri: string;
}

const fetchLegalAct = async ({ pageIndex, pageSize }: LegalActQueryState): Promise<LegalAct> => {
  const response = await Api.get(`/links-page/links`, {
    params: {
      pageIndex,
      pageSize,
    }
  })
  return { ...response.data, totalPageSize: Math.ceil(response.data.totalSize / pageSize) }
}

const LEGAL_ACT_QUERY_KEY = 'legalAct'

export const useLegalActQuery = ({ pageIndex, pageSize }: LegalActQueryState) => {
  return useQuery({
    queryKey: [LEGAL_ACT_QUERY_KEY, pageIndex, pageSize],
    queryFn: () => fetchLegalAct({ pageIndex, pageSize }),
    placeholderData: keepPreviousData
  })
}

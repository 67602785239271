import Logo from '../../assets/icons/Logo'
import { Link, useLocation } from 'react-router-dom'
import { cn } from '../../lib/utils'

const MENU_LINKS = [{
  key: 0,
  name: 'Құжаттар топтамасы',
  to: '/'
}, {
  key: 1,
  name: 'Құқықтық негіздер',
  to: '/legal'
}, {
  key: 2,
  name: 'Ашық API',
  to: '/open-api'
}]

const PROJECT_LINKS = [
  {
    key: 0,
    name: 'abai.institute',
    to: 'https://abai.institute'
  },
  {
    key: 1,
    name: 'emle.kz',
    to: 'https://emle.kz'
  },
  {
    key: 2,
    name: 'balatili.kz',
    to: 'https://balatili.kz'
  },
  {
    key: 3,
    name: 'tilqural.kz',
    to: 'https://tilqural.kz'
  },
  {
    key: 4,
    name: 'tilalemi.kz',
    to: 'https://tilalemi.kz'
  },
  {
    key: 5,
    name: 'sozdikqor.kz',
    to: 'https://sozdikqor.kz'
  },
  {
    key: 6,
    name: 'qazlatyn.kz',
    to: 'https://qazlatyn.kz'
  },
  {
    key: 7,
    name: 'termincom.kz',
    to: 'https://termincom.kz'
  },
]
export default function Footer () {
  const location = useLocation()

  const isHomePage = location.pathname === '/'

  return (
    <div className={cn('pt-[40px] px-4 pb-[32px] text-left tablet:px-6 tablet:pt-[56px] tablet:pb-[46px] desktop:pb-[40px]', {
      'bg-[#F4FBFF]': isHomePage,
      'bg-white': !isHomePage
    })}>
      <div className="grid grid-cols-1 gap-8 tablet:grid-cols-3 max-w-[1120px] mx-auto">
        <div className="flex flex-col gap-3">
          <Logo className="h-[30px] w-fit" />
          <span className="w-[250px] text-sm leading-[24px] desktop:w-[350px]">Қазақ тіліндегі құжаттардың электронды қоры</span>
        </div>

        <div className="flex flex-col gap-4 tablet:pl-10 desktop:ml-auto">
          <h2 className="font-semibold">Бөлімдер</h2>

         <div className="flex flex-col gap-2">
           {MENU_LINKS.map((link) => (
             <Link key={link.key} to={link.to} className="text-sm hover:text-primary transition">{link.name}</Link>
           ))}
         </div>
        </div>

        <div className="flex flex-col gap-4 tablet:ml-auto">
          <h2 className="font-semibold">Басқа жобаларымыз</h2>

          <div className="grid grid-cols-2 gap-2 gap-x-6 w-[220px]">
            {PROJECT_LINKS.map((link) => (
              <Link key={link.key} to={link.to} target="_blank" className="text-sm hover:text-primary transition">{link.name}</Link>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full h-[1px] bg-[#DDD] my-[36px] max-w-[1120px] mx-auto desktop:hidden"></div>

      <div className="text-gray text-sm max-w-[1120px] mx-auto desktop:mt-[40px]">© 2023 “Qujat.kz” Барлық құқықтар сақталған.</div>
    </div>
  )
}

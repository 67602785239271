interface IProps extends React.SVGProps<SVGSVGElement> {
  className?: string
}
export function HamburgerMenu({ className = '', ...rest }: IProps) {
  return (
    <svg
      {...rest}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor">
      <path
        d="M4 12H20M4 6H20M4 18H20"
        stroke="#667A8B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

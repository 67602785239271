import Logo from '../../assets/icons/Logo'
import { HamburgerMenu } from '../../assets/icons/HamburgerMenu'
import { useState } from 'react'
import Cross from '../../assets/icons/Cross'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { cn } from '../../lib/utils'

const LINKS = [
  {
    name: 'Басты бет',
    to: '/',
  },
  {
    name: 'Құқықтық негіздер',
    to: '/legal',
  },
  {
    name: 'Ашық API',
    to: '/open-api',
  },
]

export default function Header() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <header className="fixed top-0 w-full left-0 p-4 tablet:p-6 desktop:p-7 bg-white shadow desktop:px-0 z-[100] max-h-[80px]">
      <section className="flex justify-between max-w-[1120px] mx-auto items-center">
        <div onClick={() => navigate('/')}>
          <Logo className="w-auto h-[24px] desktop:h-[32px] cursor-pointer hover:opacity-70 transition-all duration-300" />
        </div>

        <div className="hidden tablet:flex gap-6 desktop:gap-10">
          {LINKS.map((link) => (
            <CustomLink key={link.to} to={link.to} closeMenu={() => setIsMenuOpen(false)}>
              {link.name}
            </CustomLink>
          ))}
        </div>

        <div className="tablet:hidden">
          {isMenuOpen ? (
            <Cross onClick={() => setIsMenuOpen(false)} className="w-[28px] h-[28px]" />
          ) : (
            <HamburgerMenu
              onClick={() => setIsMenuOpen(true)}
              className="w-[24px] h-[24px]"
            />
          )}
        </div>

        {isMenuOpen && (
          <div className="absolute h-screen w-full left-0 top-[56px] bg-modal-background tablet:hidden z-10">
            <div className="h-[228px] w-full bg-white flex flex-col gap-6 p-4 text-left justify-center">
              {LINKS.map((link) => (
                <CustomLink closeMenu={() => setIsMenuOpen(false)} key={link.to} to={link.to}>
                  {link.name}
                </CustomLink>
              ))}
            </div>
            <div onClick={() => setIsMenuOpen(false)} className="h-full"></div>
          </div>
        )}
      </section>
    </header>
  )
}

const CustomLink = ({
  to,
  children,
  closeMenu,
}: {
  to: string
  children: JSX.Element | string
  closeMenu: () => void
}) => {
  const location = useLocation()

  const isActive = location.pathname === to

  return (
    <Link
      to={to}
      className={cn('hover:text-primary tablet:text-sm desktop:text-base font-medium tablet:font-normal', {
        'text-primary': isActive,
      })}
      onClick={closeMenu}>
      {children}
    </Link>
  )
}

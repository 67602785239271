import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'
import Pagination from '../components/ui/Pagination'
import { ILegalAct, useLegalActQuery } from '../queries/legalAct/queries'
import MoveRight from '../assets/icons/MoveRight'
import FileWithStroke from '../assets/icons/FileWithStroke'

const DEFAULT_PAGE_SIZE = 12;

export default function LegalActList() {
  const [searchParams, _] = useSearchParams();

  const page = useMemo<number>(() => {
    return Number(searchParams.get('page') ?? 0)
  }, [searchParams.get('page')]);

  const {data} = useLegalActQuery({ pageIndex: page, pageSize: DEFAULT_PAGE_SIZE });

  return (
    <section className="pt-[40px] pb-[64px] px-4 min-h-[850px] tablet:px-6 desktop:px-0 tablet:pt-[56px] tablet:pb-[80px] desktop:pt-[64px] desktop:pb-[80px]">
      <h2 className="text-left text-2xl font-semibold pb-8 tablet:pb-[40px] tablet:text-[28px] desktop:pb-[64px] desktop:text-[36px]">Құқықтық негіздер</h2>

      <div className="grid grid-cols-1 gap-y-4 mb-8 tablet:gap-6 tablet:grid-cols-3 desktop:gap-8 desktop:grid-cols-4 tablet:mb-[48px]">
        {data?.responseData?.map((legalAct) => (
          <LegalActItem key={legalAct.id} {...legalAct} />
        ))}
      </div>

      {(data?.totalPageSize ?? 1 ) > 1 && (<Pagination totalPageSize={data?.totalPageSize ?? 1} currentPage={page} />)}
    </section>
  )
}

function LegalActItem({ nameKz, uri }:ILegalAct) {

  const handleClick = () => {
    window.open(uri, '_blank')
  }

  return (
    <div onClick={handleClick} className="p-4 cursor-pointer bg-white shadow rounded-lg group border border-transparent hover:border-primary transition-all hover:shadow-none hover:bg-[#F6FCFF] duration-300 flex flex-col">
      <div className="flex justify-between items-center mb-6">
        <FileWithStroke className="text-gray w-[32px] h-[32px] desktop:w-[40px] desktop:h-[40px]" />

        <MoveRight className="text-primary w-[24px] h-[24px] group-hover:-rotate-45 transition-all duration-300" />
      </div>

      <div className="text-left my-auto">{nameKz}</div>
    </div>
  )
}

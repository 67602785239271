import LinkBack from '../components/shared/LinkBack'
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  usePopularDocumentsQuery,
  useSubCategoryByIdQuery,
} from '../queries/subCategoryDocument/queries'
import { Input } from '../components/ui/input'
import Search from '../assets/icons/Search'
import { Button } from '../components/ui/button'
import { useEffect, useMemo, useState } from 'react'
import { useDebounce } from '@uidotdev/usehooks'
import {
  DropdownMenu,
  DropdownMenuContent, DropdownMenuItem,
  DropdownMenuTrigger,
} from '../components/ui/dropdown-menu'
import {
  Download,
  Eye, File,
  MoreVertical,
  Printer,
} from 'lucide-react'
import Pagination from '../components/ui/Pagination'
import { IDocument, useSubCategoryDocumentsMutation } from '../queries/subCategoryDocument/mutations'
import useDocumentActions from '../hooks/useDocumentActions'


const DEFAULT_PAGE_SIZE = 12;

export default function SubCategoryDocuments() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useMemo<number>(() => {
    return Number(searchParams.get('page') ?? 0)
  }, [searchParams.get('page')])
  const categoryId = params.categoryId;
  const subCategoryId = params.subCategoryId;

  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 500)

  useEffect(() => {
    if (debouncedSearchValue) {
      setSearchParams({ nameSearchPattern: searchValue })
    }
  }, [debouncedSearchValue])

  const { data: currentSubCategory } = useSubCategoryByIdQuery({ parentCategoryId: categoryId ?? '', subCategoryId: subCategoryId ?? '' })
  const { data, mutate, isPending } = useSubCategoryDocumentsMutation({ categoryId: categoryId ?? '', subCategoryId: subCategoryId ?? '', nameSearchPattern: debouncedSearchValue, pageIndex: page, pageSize: DEFAULT_PAGE_SIZE })

  useEffect(() => {
    const nameSearchPattern = searchParams.get('nameSearchPattern') ?? '';
    setSearchValue(nameSearchPattern)
  }, [])

  useEffect(() => {
    mutate({ nameSearchPattern: debouncedSearchValue, categoryId: categoryId ?? '', subCategoryId: subCategoryId ?? '', pageIndex: page, pageSize: DEFAULT_PAGE_SIZE })
  }, [debouncedSearchValue, page])

  const handleClick = () => {
    mutate({ nameSearchPattern: debouncedSearchValue, categoryId: categoryId ?? '', subCategoryId: subCategoryId ?? '', pageIndex: page, pageSize: DEFAULT_PAGE_SIZE })
  }

  return (<section className="pt-4 pb-[64px] px-4 min-h-[850px] tablet:px-6 desktop:px-0 desktop:pt-[40px]">
    <LinkBack className="mb-4 tablet:mb-8 desktop:mb-[32px]" to={`/category/${params.categoryId}`} />

    <h2 className="text-left text-2xl font-semibold pb-[24px] tablet:pb-8 desktop:pb-[40px] desktop:text-[28px]">{currentSubCategory?.responseData.nameKz}</h2>
    <div className="flex flex-col tablet:flex-row tablet:gap-6 h-fit desktop:gap-8">
      <div className="w-full mb-[64px] tablet:mb-[48px] h-fit">
        <div className="flex gap-2 mb-6 tablet:gap-4 desktop:mb-8">
          <div className="flex-grow">
            <Input
              onChange={(event) => setSearchValue(event.target.value)}
              value={searchValue}
              className="border-none peer pl-10 w-full shadow placeholder:text-text-gray py-3 h-[48px] desktop:h-[56px] desktop:pl-[52px] desktop:text-lg"
              placeholder="Іздеу"
              icon={<Search className="peer-focus:text-secondary transition text-text-gray absolute ml-2 w-[24px] h-[24px] desktop:ml-4" />}
            />
          </div>
          <Button isLoading={isPending} onClick={handleClick} className="h-[48px] w-[66px] font-normal text-base tablet:w-[90px] desktop:w-[120px] desktop:h-[56px]">Іздеу</Button>
        </div>

        <div className="flex flex-col gap-4 mb-8">
          {data?.responseData?.map((document) => (
            <SubCategoryDocumentItem key={document.id} {...document} />
          ))}
        </div>

        {(data?.totalPageSize ?? 1) > 1 && (
          <Pagination className="tablet:hidden" totalPageSize={data?.totalPageSize ?? 1} currentPage={page} />
        )}
      </div>

      <PopularDocuments />
    </div>

    {(data?.totalPageSize ?? 1) > 1 && (
      <Pagination className="hidden tablet:flex" totalPageSize={data?.totalPageSize ?? 1} currentPage={page} />
    )}
  </section>)
}

function SubCategoryDocumentItem({ nameKz, id, sourceContentBlob }: IDocument) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const categoryId = params.categoryId;
  const subCategoryId = params.subCategoryId;

  const { handlePrint, handleDownload } = useDocumentActions()

  const handleClick = () => {
    navigate({
      pathname: `/category/${categoryId}/${subCategoryId}/${id}`,
      search: createSearchParams({
        backUrl: `${location.pathname}${location.search}`
      }).toString()
    })
  }

  return (
    <div onClick={handleClick} className="p-5 bg-white flex items-center justify-between rounded-lg shadow hover:shadow-none cursor-pointer transition-all duration-300 gap-4 desktop:px-5 desktop:py-6 desktop:text-xl">
      <span className="text-left font-medium">{nameKz}</span>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <MoreVertical className="max-w-[18px] max-h-[18px] desktop:max-w-[24px] desktop:max-h-[24px] shrink-0 grow text-gray cursor-pointer hover:opacity-70 duration-300" />
        </DropdownMenuTrigger>
        <DropdownMenuContent side="left" className="w-56 !left-0 border-none">
          <DropdownMenuItem onClick={handleClick} className="cursor-pointer">
            <Eye className="mr-3 w-[24px] h-[24px] text-primary" />

            <span className="text-base font-medium">Алдын ала қарау</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleDownload(sourceContentBlob?.uri, categoryId ?? '', subCategoryId ?? '', id)} className="cursor-pointer">
            <Download className="mr-3 w-[24px] h-[24px] text-primary" />
            <span className="text-base font-medium">Жүктеу</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handlePrint(sourceContentBlob?.uri, sourceContentBlob?.extension, categoryId ?? '', subCategoryId ?? '', id)} className="cursor-pointer">
            <Printer className="mr-3 w-[24px] h-[24px] text-lg text-primary" />
            <span className="text-base font-medium">Басып шығару</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

function PopularDocuments() {
  const { data } = usePopularDocumentsQuery();

  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (document: IDocument) => {
    navigate({
      pathname: `/category/${document?.parentSubcategory?.parentCategory?.id}/${document?.parentSubcategory?.id}/${document?.id}`,
      search: createSearchParams({
        backUrl: `${location.pathname}${location.search}`
      }).toString()
    })
  }

  return (
    <div className="w-full shadow rounded-lg overflow-hidden h-fit tablet:max-w-[224px] tablet:mt-[72px] desktop:mt-[88px]">
      <div className="px-4 py-6 text-gray text-sm bg-[#E5F1F8] text-left">Көп жүктелген құжаттар</div>


      <div className="p-4 flex flex-col gap-2 bg-white">
        {data?.responseData?.map((document) => (
          <div key={document.id + "-" + document.parentSubcategory.id + "-" + document.parentSubcategory.parentCategory.id} onClick={() => handleClick(document)} className="group hover:bg-[#F6FCFF] duration-300 hover:border-primary p-3 cursor-pointer flex items-center gap-2 rounded-lg border border-[#E6E6E6] transition-all text-left">
            <File className="w-[24px] h-[24px] shrink-0" />
            <span className="text-sm">{document.nameKz}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

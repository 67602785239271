import { Api } from '../../lib/axios'
import { useMutation } from '@tanstack/react-query'

type DocumentPrintedState = {
  categoryId: string;
  subCategoryId: string;
  documentId: string;
}

const documentPrinted = async ({ categoryId, subCategoryId, documentId }: DocumentPrintedState): Promise<void> => {
  await Api.post(`/statistics-triggers/category/${categoryId}/subcategories/${subCategoryId}/document/${documentId}/printed`)
}

export const useDocumentPrintedMutation = () => {
  return useMutation<void, unknown, DocumentPrintedState>({
    mutationFn: async ({ categoryId, subCategoryId, documentId }) => documentPrinted({ categoryId, subCategoryId, documentId }),
    retry: false
  })
}

type DocumentViewedState = {
  categoryId: string;
  subCategoryId: string;
  documentId: string;
}

const documentViewed = async ({ categoryId, subCategoryId, documentId }: DocumentViewedState): Promise<void> => {
  await Api.post(`/statistics-triggers/category/${categoryId}/subcategories/${subCategoryId}/document/${documentId}/viewed`)
}

export const useDocumentViewedMutation = () => {
  return useMutation<void, unknown, DocumentViewedState>({
    mutationFn: async ({ categoryId, subCategoryId, documentId }) => documentViewed({ categoryId, subCategoryId, documentId }),
    retry: false
  })
}

type DocumentDownloadedState = {
  categoryId: string;
  subCategoryId: string;
  documentId: string;
}

const documentDownloaded = async ({ categoryId, subCategoryId, documentId }: DocumentDownloadedState): Promise<void> => {
  await Api.post(`/statistics-triggers/category/${categoryId}/subcategories/${subCategoryId}/document/${documentId}/downloaded`)
}

export const useDocumentDownloadedMutation = () => {
  return useMutation<void, unknown, DocumentDownloadedState>({
    mutationFn: async ({ categoryId, subCategoryId, documentId }) => documentDownloaded({ categoryId, subCategoryId, documentId }),
    retry: false
  })
}

type DocumentSubscribeEmailState = {
  categoryId: string;
  subCategoryId: string;
  documentId: string;
  email: string;
}

const documentSubscribeEmail = async ({ categoryId, subCategoryId, documentId, email }: DocumentSubscribeEmailState): Promise<void> => {
  await Api.post(`/single-document-page/category/${categoryId}/subcategories/${subCategoryId}/document/${documentId}/actions/send-to`, { email })
}

export const useDocumentSubscribeEmailMutation = () => {
  return useMutation<void, unknown, DocumentSubscribeEmailState>({
    mutationFn: async ({ categoryId, subCategoryId, documentId, email }) => documentSubscribeEmail({ categoryId, subCategoryId, documentId, email }),
    retry: false
  })
}

import { Input } from '../components/ui/input'
import { useEffect, useMemo, useState } from 'react'
import { useDebounce } from '@uidotdev/usehooks'
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {default as SearchIcon} from '../assets/icons/Search'
import { SearchDocumentData } from '../queries/home/mutations'
import { ChevronRight } from 'lucide-react'
import MoveRight from '../assets/icons/MoveRight'
import Pagination from '../components/ui/Pagination'
import { useSearchDocumentQuery } from '../queries/search/queries'
import EmptyErrorImage from '../assets/images/empty-error.png'

const DEFAULT_PAGE_SIZE = 12;
export default function Search() {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useMemo<number>(() => {
    return Number(searchParams.get('page') ?? 0)
  }, [searchParams.get('page')])
  const nameSearchPattern = searchParams.get('nameSearchPattern') ?? '';

  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 300)

  useEffect(() => {
    const nameSearchPattern = searchParams.get('nameSearchPattern') ?? '';
      setSearchValue(nameSearchPattern)
  }, [])

  const { data, isPending, isSuccess } = useSearchDocumentQuery({ nameSearchPattern: nameSearchPattern, pageIndex: page, pageSize: DEFAULT_PAGE_SIZE })

  return (
    <section className="pt-[40px] pb-[80px] px-4 min-h-[850px] tablet:px-6 desktop:px-0 tablet:pt-[56px] tablet:pb-[80px] desktop:pt-[64px] desktop:pb-[80px] desktop:max-w-[830px]">
      <h2 className="text-left text-2xl font-semibold pb-[40px] tablet:pb-[32px] tablet:text-[28px] desktop:pb-[40px] desktop:text-[36px]">Нәтижелер</h2>

      <div className="w-full pb-8 tablet:pb-6 desktop:pb-8">
        <Input
          onChange={(event) => {
            setSearchValue(event.target.value)

            searchParams.set('nameSearchPattern', event.target.value)
            setSearchParams(searchParams)
          }}
          value={searchValue}
          className="border-none peer pl-10 w-full shadow placeholder:text-text-gray py-3 h-[48px] desktop:h-[56px] desktop:pl-[52px] desktop:text-lg"
          placeholder="Іздеу"
          icon={ <SearchIcon className="peer-focus:text-secondary transition text-text-gray absolute ml-2 w-[24px] h-[24px] desktop:ml-4" />}
        />
      </div>

      {(data?.responseData?.length ?? 0) > 0 && (
        <div>
          <div className="flex flex-col gap-4 mb-8 desktop:mb-[48px]">
            {data?.responseData?.map((document) => (
              <SearchItem {...document} />
            ))}
          </div>

          {(data?.totalPageSize ?? 1) > 1 && (
            <Pagination className="desktop:justify-start" totalPageSize={data?.totalPageSize ?? 1} currentPage={page} />
          )}
        </div>
      )}

      {isSuccess && !isPending && (data?.responseData?.length ?? 0) === 0 && (
        <EmptyData />
      )}
    </section>
  )
}

const SearchItem = ({id, nameKz, parentSubcategory }:SearchDocumentData) => {
  const navigate = useNavigate()
  const location = useLocation();
  const handleClick = () => {
    navigate({
      pathname: `/category/${parentSubcategory?.parentCategory?.id}/${parentSubcategory?.id}/${id}`,
      search: createSearchParams({
        backUrl: `${location.pathname}${location.search}`
      }).toString()
    })
  }

  return (
    <div onClick={handleClick} className="pl-5 p-4 cursor-pointer flex bg-white shadow transition-all hover:shadow-none rounded-lg gap-4">
      <div className="w-full">
        <div className="text-left mb-3">{nameKz}</div>
        <div className="text-xs flex items-center">
          <div className="rounded bg-[#E8F6FF] px-2 py-1 text-[#58839D]">{parentSubcategory?.parentCategory?.nameKz}</div>
          <ChevronRight className="w-[16px] h-[16px] mx-1 text-gray" />
          <div className="rounded bg-[#ECF5FB] px-2 py-1 text-[#58839D]">{parentSubcategory?.nameKz}</div>
        </div>
      </div>

      <MoveRight className="w-[24px] h-[24px] shrink-0 grow my-auto text-gray" />
    </div>
  )
}

const EmptyData = () => {
  return (
    <div className="pb-[120px] tablet:flex tablet:items-center desktop:pt-[60px]">
      <img className="w-[156px] h-[156px] mb-8 mx-auto tablet:w-[220px] tablet:h-[220px] tablet:mb-4 tablet:mx-0 tablet:mr-6 desktop:w-[250px] desktop:h-[250px] desktop:mr-[40px]" src={EmptyErrorImage} />

      <div className="flex flex-col text-center gap-2 tablet:text-left">
        <h2 className="text-lg font-semibold tablet:text-xl desktop:text-2xl">Еш нәрсе табылмады</h2>

        <span className="text-text-gray">Іздеуіңізге сәйкес ешбір құжат <br /> немесе негіз таба алмаймыз</span>
      </div>
    </div>
  )
}

import { Api } from '../../lib/axios'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { PaginationResponse } from '../types'

type DocumentCount = {
  responseData: {
    documentCount: number
  }
}

const fetchDocumentCount = async (): Promise<DocumentCount> => {
  const response = await Api.get('/main-page/documents/count')
  return response.data
}

const DOCUMENT_COUNT_QUERY_KEY = 'documentCount'
export const useDocumentCountQuery = () => {
  return useQuery({
    queryKey: [DOCUMENT_COUNT_QUERY_KEY],
    queryFn: fetchDocumentCount,
  })
}

type CategoriesState = {
  pageIndex: number
  pageSize: number
}

type Categories = PaginationResponse & {
  responseData: ICategory[]
}

export interface ICategory {
  id: number;
  nameKz: string;
  iconBlob: {
    uri: string;
  }
  documentsCount: {
    documentCount: number
  }
}

const fetchCategories = async ({ pageSize, pageIndex }: CategoriesState): Promise<Categories> => {
  const response = await Api.get('/main-page/categories', {
    params: {
      pageSize,
      pageIndex,
    },
  })
  return { ...response.data, totalPageSize: Math.ceil(response.data.totalSize / pageSize) }
}

const CATEGORIES_QUERY_KEY = 'categories'

export const useCategoriesQuery = ({ pageIndex, pageSize }: CategoriesState) => {
  return useQuery({
    queryKey: [CATEGORIES_QUERY_KEY, pageSize, pageIndex],
    queryFn: () => fetchCategories({pageSize, pageIndex}),
    staleTime: 5000,
    placeholderData: keepPreviousData
  })
}

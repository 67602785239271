import { useState, useEffect } from 'react';

export enum Breakpoint {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}
interface IDimensions {
  width: number;
  height: number;
}

function getWindowDimensions(): IDimensions {
  const { innerWidth: width, innerHeight: height } = window || {};

  return {
    width,
    height,
  };
}

export const BREAKPOINTS = {
  [Breakpoint.mobile]: 0,
  [Breakpoint.tablet]: 650,
  [Breakpoint.desktop]: 960,
};

export function useBreakpoint(): Breakpoint {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(Breakpoint.desktop);

  useEffect(() => {
    function handleResize(): void {
      const { width } = getWindowDimensions();

      if (width >= BREAKPOINTS[Breakpoint.desktop]) {
        setBreakpoint(Breakpoint.desktop);
        return;
      }

      if (width >= BREAKPOINTS[Breakpoint.tablet]) {
        setBreakpoint(Breakpoint.tablet);
        return;
      }

      setBreakpoint(Breakpoint.mobile);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
}

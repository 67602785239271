import { useDocumentStore } from '../store/documentStore'
import { shallow } from 'zustand/shallow'
import { IDocumentExtension } from '../queries/subCategoryDocument/mutations'
import { useDocumentDownloadedMutation, useDocumentPrintedMutation } from '../queries/document/mutations'

interface IProps {
  categoryId: string
  subCategoryId: string;
  documentId: string;
}

export default function useDocumentActions() {
  const printedDocumentList = useDocumentStore(state => state.printedDocumentList, shallow)
  const setPrintedDocument = useDocumentStore(state => state.setPrintedDocument)
  const viewedDocumentList = useDocumentStore(state => state.viewedDocumentList, shallow)
  const setViewedDocument = useDocumentStore(state => state.setViewedDocument)
  const downloadedDocumentList = useDocumentStore(state => state.downloadedDocumentList, shallow)
  const setDownloadedDocument = useDocumentStore(state => state.setDownloadedDocument)


  const { mutate: mutateDocumentPrinted  } = useDocumentPrintedMutation()
  const { mutate: mutateDocumentDownloaded } = useDocumentDownloadedMutation()

  const handlePrint = (url: string | undefined, extension: IDocumentExtension | undefined, categoryId: string, subCategoryId: string, documentId: string) => {
    const modifiedDocumentId = `${categoryId}-${subCategoryId}-${documentId}`

    if (!printedDocumentList.includes(modifiedDocumentId)) {
      mutateDocumentPrinted({ categoryId: categoryId ?? '', subCategoryId: subCategoryId ?? '', documentId: documentId ?? '' })
      setPrintedDocument(modifiedDocumentId);
    }

    if (extension !== '.pdf') {
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${url}`, '_blank')
    }

    if (extension === '.pdf') {
      window.open(url, '_blank')
    }
  }

  const handleDownload = (url: string | undefined,  categoryId: string, subCategoryId: string, documentId: string) => {
    const modifiedDocumentId = `${categoryId}-${subCategoryId}-${documentId}`

    if (!downloadedDocumentList.includes(modifiedDocumentId)) {
      mutateDocumentDownloaded({ categoryId: categoryId ?? '', subCategoryId: subCategoryId ?? '', documentId: documentId ?? '' })
      setDownloadedDocument(modifiedDocumentId);
    }

    window.open(url, '_blank')
  }

  return {
    handlePrint,
    handleDownload
  }
}

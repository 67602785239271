import { PaginationResponse } from '../types'
import { Api } from '../../lib/axios'
import { useMutation } from '@tanstack/react-query'

type SubCategoryDocumentsState = {
  categoryId: string;
  subCategoryId: string;
  pageIndex: number;
  pageSize: number;
  nameSearchPattern: string
}

type SubCategoryDocuments = PaginationResponse & {
  responseData: IDocument[]
}

export interface IDocument {
  id: string;
  nameKz: string
  parentSubcategory: {
    id: string;
    parentCategory: {
      id: string;
    }
  }
  sourceContentBlob: IBlobDocument
  filledSampleContentBlob: IBlobDocument
}

export interface IBlobDocument {
  id: string;
  fileName: string;
  name: string;
  extension: IDocumentExtension;
  mimeType: '"application/pdf' | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  uri: string;
}

export type IDocumentExtension = '.docx' | '.pdf';

const fetchSubCategoryDocuments = async ({ categoryId, subCategoryId, pageIndex, pageSize, nameSearchPattern }: SubCategoryDocumentsState): Promise<SubCategoryDocuments> => {
  const response = await Api.get(`/subcategories-page/${categoryId}/subcategories/${subCategoryId}/documents`, {
    params: {
      pageSize,
      pageIndex,
      nameSearchPattern
    }
  })
  return { ...response.data, totalPageSize: Math.ceil(response.data.totalSize / pageSize) }
}

export const useSubCategoryDocumentsMutation = ({ categoryId, subCategoryId, pageIndex, pageSize, nameSearchPattern }: SubCategoryDocumentsState) => {
  return useMutation<SubCategoryDocuments, unknown, SubCategoryDocumentsState>({
    mutationFn: async () => fetchSubCategoryDocuments({ categoryId, subCategoryId, pageIndex, pageSize, nameSearchPattern }),
    retry: false
  })
}

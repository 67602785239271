import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { useCategoryByIdQuery, useSubCategoriesQuery } from '../queries/subCategory/queries'
import LinkBack from '../components/shared/LinkBack'
import Pagination from '../components/ui/Pagination'
import SubCategoryItem from '../components/shared/SubCategoryItem'


const DEFAULT_PAGE_SIZE = 12
export default function SubCategory() {
  const [searchParams, _] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();

  const categoryId = params.categoryId;
  const page = useMemo<number>(() => {
    return Number(searchParams.get('page') ?? 0)
  }, [searchParams.get('page')]);

  const { data: currentCategory, isError } = useCategoryByIdQuery({ id: categoryId ?? '' })
  const {data} = useSubCategoriesQuery({ id: categoryId ?? '', pageIndex: page, pageSize: DEFAULT_PAGE_SIZE });

  useEffect(() => {
    if (isError) {
      navigate('/')
    }
  }, [isError])

  return (
    <section className="pt-4 pb-[64px] px-4 min-h-[850px] tablet:px-6 desktop:px-0 desktop:pt-[40px]">
      <LinkBack className="mb-4 tablet:mb-8 desktop:mb-[32px]" to="/" />

      <h2 className="text-left text-2xl font-semibold pb-[44px] desktop:text-[36px] desktop:pb-[64px]">{currentCategory?.responseData.nameKz}</h2>

      <div className="grid grid-cols-1 tablet:grid-cols-3 tablet:gap-x-6 gap-12 mb-8 tablet:mb-[48px] max-w-[1120px] desktop:mx-auto desktop:grid-cols-4 desktop:gap-x-8 desktop:gap-y-16">
        {data?.responseData?.map((subCategory) => (
          <SubCategoryItem key={subCategory.id} {...subCategory} />
        ))}
      </div>

      {(data?.totalPageSize ?? 1) > 1 && (<Pagination totalPageSize={data?.totalPageSize ?? 1} currentPage={page} />)}
    </section>
  )
}

import { useEffect } from 'react';
import { useHistoryStore } from '../store/historyStore'
import { useLocation } from 'react-router-dom'

export const useSaveHistory = (): void => {
  const location = useLocation();

  const setHistory = useHistoryStore(({ setHistory }) => setHistory);

  useEffect(() => {
    const currentRoute = location.pathname + location.search;

    setHistory(currentRoute);
  }, [setHistory, location]);
};
